<template>
  <div
    v-loading="loading"
    id="app-mobile"
    style="max-width: 500px"
  >
    <!-- <van-nav-bar class="boxTop" title="问卷标题">
      <template #left>
        <van-icon
          class="iconLeft"
          name="arrow-left"
          size="18"
          @click="onClickLeft"
        />
      </template>
      <template #right>
        <van-icon class="iconRight" name="ellipsis" size="18" />
      </template>
    </van-nav-bar> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "mobile",
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
$room: 1020 / 375;
.boxTop {
  width: 100vw;
  height: 50px * $room;
  background: #4f835e;
  padding: 17px * $room 10px * $room 0;
  position: sticky;
  top: 0;
  ::v-deep .van-nav-bar__title {
    color: #fff;
    font-size: 18px * $room;
    line-height: 20px * $room;
  }
}
.iconLeft {
  color: #fff;
}
.topCon {
  font-size: 20px * $room;
}
.iconRight {
  color: #fff;
}
</style>
